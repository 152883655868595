import React, { Component } from "react";
import profileImage from '../img/DionMisicProfile.jpg';
import SlantedBg from "../util/slantedbg";
import Navbar from "../util/navbar";
import Footer from "../util/footer";
import Grid from '@material-ui/core/Grid';
import TransitionWrapper from "../util/transition";
import { Link } from "react-router-dom";
import DocumentMeta from 'react-document-meta';

export class AboutTop extends Component {
        constructor(props) {
                super(props);
                this.colorPalette = props.colorPalette;
                this.fonts = { 
                        color: this.colorPalette.fontcolour, 
                        borderColor: this.colorPalette.fontcolour,
                }
        }

        componentDidMount() {
                document.documentElement.style.backgroundColor = this.colorPalette.thirdcolor;
                document.body.style.background = this.colorPalette.thirdcolor;
                document.body.style.borderTop = "7px solid" + this.colorPalette.thirdcolor;
                window.scrollTo(0, 0);
        }

        render() { 
                const meta = {
                        title: "Dion Misic ― About Me",
                        description: 'Read about Dion Misic, a 20 year old passionate developer who believes in pushing boundaries - based in Sydney, Australia.',
                        canonical: 'http://dionmisic.com',
                        index: 'http://dionmisic.com',
                        me: 'http://dionmisic.com/about',
                        author: '/about',
                        license: '/privacy',
                        meta: {
                            charset: 'utf-8',
                            google: 'notranslate',
                            name: {
                                keywords: 'dion, misic, about, me, websockets, python, pycon, website, developer, canberra, sydney, designer'
                            }
                        }
                }

                return (
                        <section>
                                <DocumentMeta {...meta} />
                                <SlantedBg degree="-11"
                                        bgcolor={this.colorPalette.firstcolor}
                                        secondcolor={this.colorPalette.secondcolor}
                                        thirdcolor={this.colorPalette.thirdcolor}/>
                                <Navbar navColour={this.colorPalette.navcolour} colorPalette={this.colorPalette}/>
                                <TransitionWrapper>
                                        <Grid id="content" className="eighty" container spacing={24}>
                                                <Grid item xs={12} sm={12}>
                                                        <div>                                                        
                                                                <Grid container spacing={40} id="about-box">  
                                                                        <Grid item sm={3}>
                                                                                <img id="profile-image" alt="Dion Misic" src={profileImage}></img>
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={9}>
                                                                                <div className="title-container">
                                                                                        <h1 className="pager-title" style={this.fonts}>
                                                                                                Hey! — I'm Dion Misic.
                                                                                        </h1>
                                                                                        <h2 className="sub-title" style={this.fonts}>
                                                                                                A passionate developer, designer and creator looking to push boundaries in pursuit of greatness. 
                                                                                        </h2>
                                                                                </div>  
                                                                                <br/>
                                                                        </Grid>

                                                                        <Grid item md={6}>
                                                                                <h3 style={this.fonts}>
                                                                                        PROFILE
                                                                                </h3>

                                                                                <p style={this.fonts}>
                                                                                        I’m Dion Misic - a {new Date().getFullYear().toString().slice(2)} hardworking, 
                                                                                        passionate UTS BIT Alumni 
                                                                                        with a heart for design, website development, computer 
                                                                                        science, business and visual storytelling. I am currently 
                                                                                        based in Annandale, Sydney - pursuing a career as a 
                                                                                        software engineer.

                                                                                        <br/><br/>

                                                                                        I have previously been an intern at WiseTech Global and
                                                                                        Nine Entertainment and I am an annual presenter at the
                                                                                        national PyCon AU developer conference. I'm in my final 
                                                                                        year of the Bachelor of IT Co-Op Scholarship and I am 
                                                                                        always looking to push boundaries in pursuit of greatness. 

                                                                                        <br/><br/>

                                                                                        Willing to re-locate to London or San Francisco/Bay Area.
                                                                                </p>
                                                                        </Grid> 

                                                                        <Grid item md={4}>
                                                                                <h3 style={this.fonts}>
                                                                                        OCCUPATION
                                                                                </h3>

                                                                                <p style={this.fonts}>Intern at Nine Entertainment (2020)</p>
                                                                                <p className="inactive" style={this.fonts}>Associate Developer at WiseTech Global (2018-2019)</p>
                                                                                <p className="inactive" style={this.fonts}>Intern at WiseTech Global (2018)</p>
                                                                                <p className="inactive" style={this.fonts}>Data Scientist Assistant at ANU (2017-2018)</p>
                                                                        </Grid>

                                                                        <Grid item md={6}>
                                                                                <h3 style={this.fonts}>
                                                                                        AWARDS AND ACCREDITATIONS
                                                                                </h3>
                                                                                <ul style={this.fonts}>
                                                                                        <li>WiseTech code2learn Hackathon 3rd Place (2018) </li>
                                                                                        <li>Gungahlin College - Excellence in Information Technology (2017)</li>
                                                                                        <li>ANU Blueprint "Engagement and Impact" 1st Place Award (2017)</li>
                                                                                        <li>2017 and 2018 PyCon Australia Presenter</li>
                                                                                        <li>Google Digital Garage - The Online Marketing Fundamentals (2018)</li>
                                                                                        <li>UTS Co-Op Scholarship (2018)</li>
                                                                                </ul>
                                                                        </Grid>

                                                                        <Grid item md={4}>
                                                                                <h3 style={this.fonts}>
                                                                                        STUDY
                                                                                </h3>
                                                                                <p style={this.fonts}>UTS Bachelor of Information Technology - Co-Op Scholarship (2018-2020)</p>
                                                                                <p className="inactive" style={this.fonts}>ACT Senior Secondary Certificate and Tertiary Entrance Statement at Gungahlin College (2016-2017)</p>
                                                                        </Grid>

                                                                        <Grid item md={6}>
                                                                                <h3 style={this.fonts}>
                                                                                        GET IN TOUCH
                                                                                </h3>
                                                                                <div>
                                                                                        <p style={this.fonts}>
                                                                                                Feel free to check out some of my social links below or <Link style={this.fonts} to="/contact">contact me.</Link>
                                                                                        </p>
                                                                                        <Link style={this.fonts} to="https://www.linkedin.com/in/dion-misic/">LinkedIn</Link><br/>
                                                                                        <Link style={this.fonts} to="https://twitter.com/dion_misic">Twitter</Link><br/>
                                                                                        <Link style={this.fonts} to="https://stackoverflow.com/users/9797607/kingdion?tab=profile">StackOverflow</Link><br/>
                                                                                        <Link style={this.fonts} to="https://github.com/kingdion">GitHub</Link><br/>
                                                                                </div>
                                                                        </Grid>
                                                                </Grid>
                                                        </div>
                                                </Grid>
                                        </Grid>
                                </TransitionWrapper>
                        </section>
                );
        }
}

export class About extends Component {
        constructor(props) {
                super(props);
                this.colorPalette = props.colorPalette;
                this.fonts = { color: this.colorPalette.fontcolour, borderColor: this.colorPalette.fontcolour }
        }

        render() { return (
                <TransitionWrapper>
                        <Footer colorPalette={this.colorPalette}/>
                </TransitionWrapper>);
        }
}