import React, { Component } from "react";
import SlantedBg from "../util/slantedbg";
import Navbar from "../util/navbar";
import Footer from "../util/footer";
import Grid from '@material-ui/core/Grid';
import TransitionWrapper from "../util/transition";
import { Link } from "react-router-dom";
import DocumentMeta from 'react-document-meta';

export class BlogTop extends Component {
        constructor(props) {
                super(props);
                this.colorPalette = props.colorPalette;
                this.fonts = { 
                        color: this.colorPalette.fontcolour, 
                        borderColor: this.colorPalette.fontcolour,
                }
        }

        componentDidMount() {
                document.documentElement.style.backgroundColor = this.colorPalette.thirdcolor;
                document.body.style.background = this.colorPalette.thirdcolor;
                document.body.style.borderTop = "7px solid" + this.colorPalette.thirdcolor;
                window.scrollTo(0, 0);
        }

        render() { 
                const meta = {
                        title: "Dion Misic ― Blog",
                        description: 'A brief look inside the head of Dion Misic - read about his adventures in the world of technology, learn about his failures and triumphs and read about topics you are interested in.',
                        canonical: 'http://dionmisic.com',
                        index: 'http://dionmisic.com',
                        me: 'http://dionmisic.com/about',
                        author: '/about',
                        license: '/privacy',
                        meta: {
                            charset: 'utf-8',
                            google: 'notranslate',
                            name: {
                                keywords: 'dion, misic, blog, websockets, python, pycon, website, developer, canberra, sydney, designer'
                            }
                        }
                }

                return (
                        <section>
                                <DocumentMeta {...meta}/>
                                <SlantedBg degree="-11"
                                        bgcolor={this.colorPalette.firstcolor}
                                        secondcolor={this.colorPalette.secondcolor}
                                        thirdcolor={this.colorPalette.thirdcolor}/>
                                <Navbar navColour={this.colorPalette.navcolour} colorPalette={this.colorPalette}/>
                                <TransitionWrapper>
                                        <Grid id="content" className="eighty" container spacing={24}>
                                                <Grid item xs={12} sm={9}>
                                                        <div className="down">                                                        
                                                                <div className="title-container">
                                                                        <h1 className="pager-title" style={this.fonts}>
                                                                                Blog
                                                                        </h1>
                                                                        <h2 className="sub-title" style={this.fonts}>
                                                                                I write about technology, videography and other fun stuff.
                                                                        </h2>
                                                                </div>  

                                                                <Grid container spacing={40} id="blog-box">  
                                                                        <Grid item md={8}>
                                                                                <h3 style={this.fonts}>
                                                                                        ENTRIES
                                                                                </h3>
                                                                
                                                                                <ul id="blog-entries-box" style={this.fonts}>
                                                                                        <li><Link to="/blog/entry/welcome-to-the-blog"><h3 style={this.fonts}>Welcome.</h3></Link></li>
                                                                                </ul>
                                                                        </Grid> 

                                                                        <Grid item md={8}>
                                                                                <h3 style={this.fonts}>
                                                                                        VIDEOS
                                                                                </h3>
                                                                
                                                                                <ul id="blog-entries-box" style={this.fonts}>
                                                                                        <li><Link to="/blog/entry/an-introduction-to-colour-grading"><h3 style={this.fonts}>An introduction to Colour Grading.</h3></Link></li>
                                                                                </ul>
                                                                        </Grid> 
                                                                </Grid>
                                                        </div>
                                                </Grid>
                                        </Grid>
                                </TransitionWrapper>
                        </section>
                );
        }
}

export class Blog extends Component {
        constructor(props) {
                super(props);
                this.colorPalette = props.colorPalette;
                this.fonts = { color: this.colorPalette.fontcolour, borderColor: this.colorPalette.fontcolour }
        }

        render() { return (
                <TransitionWrapper>
                        <Footer onlyBottom colorPalette={this.colorPalette}/>
                </TransitionWrapper>);
        }
}