import React, { Component } from "react";

class Block extends Component {
        constructor(props) {
          super(props);

          this.bgstyle = {
                  height: props.height + '%',
                  background: props.bgcolor,
                  opacity: props.opacity,
          };
          this.nomargin = props.nomargin;
          this.children = props.children;
        }

  render() {
    if (this.nomargin) {
            return (
                    <div className="block-no-margin" style={this.bgstyle}>
                            {this.children}
                    </div>
            );
    }
    else {
            return (
                    <div className="block" style={this.bgstyle}>
                        {this.children}
                    </div>
            );
    }
  }
}

export default Block;