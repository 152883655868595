import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline';
import { Home, HomeTop } from './routes/home';
import { About, AboutTop } from './routes/about';
import { Contact, ContactTop } from './routes/contact';
import { Blog, BlogTop } from './routes/blog';
import { Privacy, PrivacyTop } from './routes/privacy';
import { CoverLetter, CoverLetterTop } from './routes/coverletter';
import { BlogWrapper } from './routes/blogs/blogwrapper';
import { BlogEntry1, BlogEntry2 } from './routes/blogs/entries';
import { Error } from './routes/error';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import TransitionWrapper from "./util/transition";
import Helmet from 'react-helmet';

class Main extends Component {
        constructor(props) {
                super(props);
                this.updateDimensions = this.updateDimensions.bind(this);
        }

        state = 
        {
                loading: true,
        };
              
        static propTypes = 
        {
                match: PropTypes.object.isRequired,
                location: PropTypes.object.isRequired,
                history: PropTypes.object.isRequired,
        }

        updateDimensions() {
                var w = window,
                    d = document,
                    documentElement = d.documentElement,
                    body = d.getElementsByTagName('body')[0],
                    width = w.innerWidth || documentElement.clientWidth || body.clientWidth,
                    height = w.innerHeight|| documentElement.clientHeight|| body.clientHeight;

                this.setState({width: width, height: height});
        }

        componentWillMount() {
                this.updateDimensions();
        }

        componentWillUnmount() {
                window.removeEventListener("resize", this.updateDimensions);
        }

        componentDidMount() {
                window.addEventListener("resize", this.updateDimensions);
                setTimeout(() => this.setState({ loading: false}), 2500); // simulates an async action, and hides the spinner
        }

        render() {

        // background, accent, top accent
        // main font
        // footers
        // hambuger, text

        const colorPalette = {
                homepage: {firstcolor: '#131313', secondcolor: '#252525', thirdcolor: '#ffdead',
                                fontcolour: "#ffdead",
                                footerfirst: "#282828", footersecond: "#353535",
                                navcolour: {hamburger: "#ffdead", text: "#ffdead"},
                        },

                homepage_footer: {firstcolor: '#ffdead', secondcolor: '#ffdead', thirdcolor: '#ffdead',
                        fontcolour: "#131313",
                        footerfirst: "#ffdead", footersecond: "#ffdead",
                        navcolour: {hamburger: "#ffdead", text: "#ffdead"},
                },

                about: {firstcolor: '#f22e58', secondcolor: '#db3055', thirdcolor: '#f44168',
                                fontcolour: "white",
                                footerfirst: "#f44168", footersecond: "#f44168",
                                navcolour: {hamburger: "white", text: "white"},
                        },

                contact: {firstcolor: '#4286f4', secondcolor: '#528ff2', thirdcolor: '#6fa4fc',
                                fontcolour: "white",
                                footerfirst: "#6fa4fc", footersecond: "#6fa4fc",
                                navcolour: {hamburger: "white", text: "white"},
                        },

                blog: {firstcolor: '#e8e8e8', secondcolor: '#f7f4f4', thirdcolor: '#ffffff',
                                fontcolour: "black",
                                footerfirst: "#ffffff", footersecond: "#ffffff",
                                navcolour: {hamburger: "black", text: "black"},
                        },

                privacy: {firstcolor: '#e8e8e8', secondcolor: '#f7f4f4', thirdcolor: '#ffffff',
                        fontcolour: "black",
                        footerfirst: "#ffffff", footersecond: "#ffffff",
                        navcolour: {hamburger: "black", text: "black"},
                },
        }

        const { loading } = this.state;
    
        if (loading) { // if your component doesn't have to wait for an async action, remove this block 
                return null; // render null when app is not ready
        }

        /*
        <BlogWrapper colorPalette={colorPalette.blog}>
                <Route render={()=><BlogEntry1 colorPalette={colorPalette.homepage}/>} exact path='/blog/entry/welcome-to-the-blog'/>
                <Route render={()=><BlogEntry2 colorPalette={colorPalette.homepage}/>} exact path='/blog/entry/an-introduction-to-colour-grading'/>
        </BlogWrapper>

                <Route render={()=><Blog colorPalette={colorPalette.homepage_footer}/>} path='/blog'/>
        <Route render={()=><Blog colorPalette={colorPalette.homepage_footer}/>} path='/blog/entry'/>

        */

        return (
                <main>
                        <Helmet>
                          <link rel="shortcut icon" href={require('./img/DionMisic.png')}/>
                        </Helmet>

                        <TransitionWrapper>
                                <div className="everything">
                                        <CssBaseline />
                                        <div className="main-content">
                                                <Switch>
                                                        <Route render={()=><HomeTop size="10" colorPalette={colorPalette.homepage}/>} exact path='/'/>
                                                        <Route render={()=><AboutTop colorPalette={colorPalette.homepage}/>} exact path='/about'/>
                                                        <Route render={()=><ContactTop colorPalette={colorPalette.homepage}/>} exact path='/contact'/>
                                                        <Route render={()=><BlogTop colorPalette={colorPalette.homepage}/>} exact path='/blog'/>
                                                        <Route render={()=><PrivacyTop colorPalette={colorPalette.homepage}/>} exact path='/privacy'/>

                                                        <Route render={()=>
                                                                <CoverLetterTop 
                                                                        company={'Atlassian'} 
                                                                        role_name={'2021 Graduate Software Developer'} 
                                                                        colorPalette={colorPalette.homepage}/>
                                                                } 
                                                        exact path='/atlassian/7972882e-c34a-4b26-a983-1d26dc04cd71'/>

                                                        <Route render={()=>
                                                                <CoverLetterTop 
                                                                        company={'Canva'} 
                                                                        role_name={'Graduate Software Engineer (JavaScript Frontend)'} 
                                                                        colorPalette={colorPalette.homepage}/>
                                                                } 
                                                        exact path='/canva/95296227-7bea-4b3f-8c81-ac10e47a53ea'/>


                                                        <Route path="*" render={()=><Error colorPalette={colorPalette.homepage}/>}/>                               
                                                </Switch>
                                        </div>
                                        <Switch>
                                                <Route render={()=><Home colorPalette={colorPalette.homepage}/>} exact path='/'/>
                                                <Route render={()=><About colorPalette={colorPalette.homepage_footer}/>} exact path='/about'/>
                                                <Route render={()=><Contact colorPalette={colorPalette.homepage_footer}/>} exact path='/contact'/>
                                                <Route render={()=><Privacy colorPalette={colorPalette.homepage_footer}/>} exact path='/privacy'/>

                                                <Route render={()=><CoverLetter colorPalette={colorPalette.homepage_footer}/>} exact path='/atlassian/7972882e-c34a-4b26-a983-1d26dc04cd71'/>
                                                <Route render={()=><CoverLetter colorPalette={colorPalette.homepage_footer}/>} exact path='/canva/95296227-7bea-4b3f-8c81-ac10e47a53ea'/>
                                        </Switch>
                                </div>
                        </TransitionWrapper>
                </main>
        );
        }
        }

        const AdaptiveMain = withRouter(Main)
        export default AdaptiveMain;
