import React from "react";
import { BrowserRouter } from 'react-router-dom'
import ReactDOM from "react-dom";
import Main from "./main";
import './index.css';
import './img/techstack/devicon-master/devicon.css';

ReactDOM.render(
  <BrowserRouter><Main/></BrowserRouter>
	, document.getElementById("root")
);
