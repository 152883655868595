import React, { Component } from "react";
import SlantedBg from "../util/slantedbg";
import Navbar from "../util/navbar";
import Footer from "../util/footer";
import Grid from '@material-ui/core/Grid';
import TransitionWrapper from "../util/transition";
import { Link } from "react-router-dom";
import DocumentMeta from 'react-document-meta';

export class PrivacyTop extends Component {
        constructor(props) {
                super(props);
                this.colorPalette = props.colorPalette;
                this.fonts = { 
                        color: this.colorPalette.fontcolour, 
                        borderColor: this.colorPalette.fontcolour,
                }
        }

        componentDidMount() {
                document.documentElement.style.backgroundColor = this.colorPalette.thirdcolor;
                document.body.style.background = this.colorPalette.thirdcolor;
                document.body.style.borderTop = "7px solid" + this.colorPalette.thirdcolor;
                window.scrollTo(0, 0);
        }

        render() { 
                const meta = {
                        title: "Dion Misic ― Privacy",
                        description: 'Learn about how your data is used on this website.',
                        canonical: 'http://dionmisic.com',
                        index: 'http://dionmisic.com',
                        me: 'http://dionmisic.com/about',
                        author: '/about',
                        license: '/privacy',
                        meta: {
                            charset: 'utf-8',
                            google: 'notranslate',
                            name: {
                                keywords: 'dion, misic, privacy, policy, websockets, python, pycon, website, developer, canberra, sydney, designer'
                            }
                        }
                }
                
                return (
                        <section>
                                <DocumentMeta {...meta}/>
                                <SlantedBg degree="-11"
                                        bgcolor={this.colorPalette.firstcolor}
                                        secondcolor={this.colorPalette.secondcolor}
                                        thirdcolor={this.colorPalette.thirdcolor}/>
                                <Navbar navColour={this.colorPalette.navcolour} colorPalette={this.colorPalette}/>
                                <TransitionWrapper>
                                        <Grid id="content" className="eighty" container spacing={24}>
                                                <Grid item xs={12} sm={9}>
                                                        <div className="down">                                                        
                                                                <div className="title-container">
                                                                        <h1 className="pager-title" style={this.fonts}>
                                                                                Privacy Policy
                                                                        </h1>
                                                                        <h2 className="sub-title" style={this.fonts}>
                                                                                Cookies and data usage on this site.
                                                                        </h2>
                                                                </div>  
                                                                <br/>

                                                                <Grid container spacing={40}>  
                                                                        <Grid item xs={12} md={10}>
                                                                                <p style={this.fonts}>
                                                                                This website does not share personal information with third parties nor do I store any 
                                                                                information about your visit to this site other than to analyze and optimize your content and 
                                                                                reading experience through the use of cookies, mainly by Google Adsense or other third parties. The only other 
                                                                                information which is collected solely by this
                                                                                website is through the contact page which is only sent through user interaction and consent and is
                                                                                by no means a requirement of the site.
                                                                                <br/><br/>
                                                                                You can turn off the use of cookies, double-click advertising or ad-partner settings at anytime by 
                                                                                changing your specific browser settings. You can read more here about any ad settings provided by
                                                                                Google and it's partners at <Link style={this.fonts} to="http://www.aboutads.info/choices/">AboutAds</Link>, this site 
                                                                                or the author of this site is not responsible for how they may use cookies.
                                                                                <br/><br/>
                                                                                I am not responsible for republished content from this site on other websites without our permission.
                                                                                This privacy policy is subject to change without notice and was last updated on 13 January 2018. 
                                                                                If you have any questions feel free to contact me through the <Link style={this.fonts} to="/contact">contact page</Link>.
                                                                                </p>
                                                                        </Grid>
                                                                </Grid>
                                                        </div>
                                                </Grid>
                                        </Grid>
                                </TransitionWrapper>
                        </section>
                );
        }
}

export class Privacy extends Component {
        constructor(props) {
                super(props);
                this.colorPalette = props.colorPalette;
                this.fonts = { color: this.colorPalette.fontcolour, borderColor: this.colorPalette.fontcolour }
        }

        render() { return (
                <TransitionWrapper>
                        <Footer onlyBottom colorPalette={this.colorPalette}/>
                </TransitionWrapper>);
        }
}