import React, { Component } from "react";

class SlantedBg extends Component {
        constructor(props) {
                super(props);
                this.children = props.children;

                this.bgstyle = {transform: 'skewY(' + props.degree + 'deg)',
                        WebkitTransform: 'skewY(' + props.degree + 'deg)',
                        MsTransform: 'skewY(' + props.degree + 'deg)',
                        marginTop: props.margin + '%',
                        backgroundColor: props.bgcolor,
                        height: props.height,
                };

                this.wrapcolor = props.wrapcolor;
                this.onlyLast = props.onlyLast
                this.particles = props.particles;
                this.second = props.second;
                this.secondcolor = props.secondcolor;
                this.thirdcolor = props.thirdcolor;
        }

        render() {
                if (this.second && !this.wrapcolor) {
                        return (
                                <div className="slanted-background" style={this.bgstyle}>


                                </div>
                        );
                }
                else if (this.wrapcolor) {
                        return (
                                <div className="behind-sbg" style={{backgroundColor: this.bgcolor}}>
                                                {this.onlyLast 
                                                ? 
                                                <div className="slanted-background" style={this.bgstyle}>
                                                        <span style={{backgroundColor: this.secondcolor}}></span>
                                                        <span style={{backgroundColor: this.secondcolor}}></span>
                                                        <span style={{backgroundColor: this.secondcolor}}></span>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                        <span style={{backgroundColor: this.thirdcolor, top: 0, marginTop: "-4px"}}></span>
                                                        <span style={{backgroundColor: this.thirdcolor, top: 0, height: "70px"}}></span>
                                                </div>
                                                :
                                                <div className="slanted-background" style={this.bgstyle}>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                        <span style={{backgroundColor: this.thirdcolor, top: 0}}></span>
                                                        <span style={{backgroundColor: this.thirdcolor, top: 0, marginBottom: "2px !important"}}></span>
                                                        <span style={{backgroundColor: this.thirdcolor, top: 0}}></span>
                                                </div>
                                                }
                                </div>

                        );
                }
                else {
                                return (
                                        <div className="slanted-background" style={this.bgstyle}>
                                                <span style={{backgroundColor: this.secondcolor}}></span>
                                                <span style={{backgroundColor: this.secondcolor}}></span>
                                                <span style={{backgroundColor: this.secondcolor}}></span>
                                                <span style={{backgroundColor: this.secondcolor}}></span>
                                                <span style={{backgroundColor: this.secondcolor}}></span>
                                                <span style={{backgroundColor: this.secondcolor}}></span>
                                                <span style={{backgroundColor: this.thirdcolor}}></span>
                                                <span style={{backgroundColor: this.thirdcolor}}></span>

                                        </div>
                                );
                        }
                }
        }

        export default SlantedBg;
