import React, { Component } from "react";
import SlantedBg from "../util/slantedbg";
import Navbar from "../util/navbar";
import Footer from "../util/footer";
import Grid from '@material-ui/core/Grid';
import TransitionWrapper from "../util/transition";
import { Link } from "react-router-dom";
import DocumentMeta from 'react-document-meta';
import Button from '@material-ui/core/Button';
import TrendingFlat from '@material-ui/icons/TrendingFlat';

export class CoverLetterTop extends Component {
        constructor(props) {
                super(props);
                this.colorPalette = props.colorPalette;
                this.fonts = { 
                        color: this.colorPalette.fontcolour, 
                        borderColor: this.colorPalette.fontcolour,
                }
        }

        componentDidMount() {
                document.documentElement.style.backgroundColor = this.colorPalette.thirdcolor;
                document.body.style.background = this.colorPalette.thirdcolor;
                document.body.style.borderTop = "7px solid" + this.colorPalette.thirdcolor;
                window.scrollTo(0, 0);
        }

        render() { 
                const { company, role_name } = this.props;

                const meta = {
                        title: `Dion Misic ― ${company} Cover Letter`,
                        description: `Dion Misic ― ${company} Cover Letter`,
                        canonical: 'http://dionmisic.com',
                        content: 'noindex',
                        index: 'http://dionmisic.com',
                        me: 'http://dionmisic.com/about',
                        author: '/about',
                        license: '/privacy',
                        meta: {
                            charset: 'utf-8',
                            google: 'notranslate',
                            name: {
                                keywords: 'dion, misic, privacy, policy, websockets, python, pycon, website, developer, canberra, sydney, designer'
                            }
                        }
                }

                const generateLetter = (company) => {
                    switch(company) { 
                        case 'Atlassian':
                            return (
                                <span>
                                    Hi there, thanks for taking the time to look at my cover letter 
                                    for the 2021 Software Developer Graduate Role.
                                    I'm Dion Misic — currently 20 years old, based in Sydney and pursuing
                                    a career in software development/engineering and design. I'm in
                                    my final year of study for my Bachelor of IT (Co-Op Scholarship) at
                                    UTS and I have previously been an intern at WiseTech Global and
                                    Nine Entertainment. I am also an annual presenter at the
                                    national PyCon AU developer conference. 

                                    <br/><br/>
                                    Based on what I have read, I'm interested in the Software Developer role at 
                                    Atlassian because:
                                    
                                    <ol>
                                        <li>
                                            I have enjoyed using Jira, BitBucket and Trello on my previous 
                                            internships/workplaces and at university. I find that it feels rewarding 
                                            to be working on products I'm <i>passionate about</i> and genuinely
                                            enjoy using.
                                        </li>
                                        <li>
                                            I enjoy public speaking at conferences! And hackathons.
                                            I've spoken at PyCon Australia (twice!) and I came third in a hackathon 
                                            in my first year of uni. 
                                        </li>
                                        <li>
                                            I'm an advocate of the open-work and 'no-bullshit' philosophies and 
                                            I think the agile Atlassian environment would compliment 
                                            my style of working.
                                        </li>
                                    </ol>

                                    I'm an ideal candidate for the role because I have extensive hands-on experience
                                    working with object-oriented languages such as Python, Java, C#, C++ and Javascript.
                                    I have experience working across full-stack software development workflows and was 
                                    brought into the technology world adopting and practising agile methodologies.

                                    <br/><br/>

                                    In my 4 years of programming experience, I find myself comfortable working on a range of 
                                    different tech stacks, including the development of back-end applications (Flask, Django, Express), 
                                    REST APIs, vanilla front-end stacks (HTML/CSS/JS), react & react-native. 
                                    
                                    <br/><br/>

                                    Additionally, I have a focus on writing <i>quality software</i>, where I have extensive
                                    experience in unit testing, smoke/regression testing and mocking knowledge using
                                    technologies such as Jest, Mocha, PhantomJS, Selenium and NUnit (to name a few).

                                    <br/><br/>

                                    Despite not studying computer science, I believe that I also have strong foundational 
                                    technical knowledge which allows me to adapt well to learning new skills and languages 
                                    to meet specific business goals and solve complex technical problems. I study computer-science 
                                    in my spare time and take computer-science electives which leads to exposure to 
                                    implementing data structures, algorithms and performance considerations when writing code.
                                

                                    <br/><br/>

                                    During my previous work experience, I have had to work closely within my teams and 
                                    also with other teams to achieve business goals. This includes experience in using git/TFS for
                                    source control and working with quality assurance/product teams to design solutions to problems.
                                    Additionally, I had to participate in code-reviews and writing software designs as apart of my previous roles.

                                    <br/><br/>

                                    <b>Final Regards</b>
                                    
                                    <br/>

                                    If you got this far - thanks! 
                                    <br/><br/>

                                    As a note, I've listed back-end as my most interested position but I'm 
                                    interested in web development in general (front-end or back-end) and 
                                    data-analytics/machine-learning/artificial-intelligence 
                                    style work. I'm happy to move around to suit business needs.

                                    <br/><br/>
                                    I am eager to see if we are a match. My best 
                                    form of contact is by email: <b>dion.misic@gmail.com</b>
                                    
                                    <br/>
                                    Thanks for the consideration!

                                    <br/><br/>

                                    <i>Dion Misic</i>
                                </span>
                            )
                        case 'Canva':
                            return (
                                <span>
                                    Hi there, thanks for taking the time to look at my cover letter 
                                    for the Graduate Software Engineer (JavaScript Frontend) role.
                                    I'm Dion Misic — currently 20 years old, based in Sydney and pursuing
                                    a career in software development/engineering and design. I'm in
                                    my penultimate year of study for my Bachelor of IT (Co-Op Scholarship) at
                                    UTS and I have previously been an intern at WiseTech Global (6 months intern, 1 year part-time) and
                                    Nine Entertainment (6 months intern until July). I am also an annual presenter at the
                                    national PyCon AU developer conference. 

                                    <br/><br/>
                                    Based on what I have read, I'm interested in working at Canva because:
                                    
                                    <ol>
                                        <li>
                                            I am passionate about graphics design & creating intuitive software - 
                                            characteristics which makes Canva seems like a really exciting workplace 
                                            as it has a blend of both worlds.
                                        </li>
                                        <li>
                                            I'm an advocate of the Canva values - I like making big goals happen
                                            and making simple software. I think the energetic Canva environment 
                                            and culture would compliment my style of working.
                                        </li>
                                        <li>
                                            I <i>like the product.</i> I've used Canva to help my younger siblings
                                            with high school assignments and I find that it feels rewarding 
                                            to be working on products I'm passionate about and genuinely
                                            enjoy using.
                                        </li>
                                    </ol>

                                    I'm an ideal candidate for the role because I have cross-discipline experience and skills.
                                    I have extensive hands-on experience
                                    working with object-oriented languages such as Python, Java, C#, C++ and Javascript.
                                    I have been apart of full-stack software development workflows and was 
                                    brought into the technology world adopting and practising agile methodologies.

                                    <br/><br/>

                                    In my 4 years of programming experience, I find myself comfortable working on a range of 
                                    different tech stacks, including the development of back-end applications (Flask, Django, Express), 
                                    REST APIs, vanilla front-end stacks (HTML/CSS/JS), react & react-native. I have practice working
                                    with docker, terraform provisioning tools, AWS lambdas, webhooks and 
                                    new features in react like Hooks, Portals and Contexts. 

                                    
                                    <br/><br/>

                                    Additionally, I have a focus on writing <i>quality software</i>, where I have extensive
                                    experience in unit testing, smoke/regression testing and mocking knowledge using
                                    technologies such as Jest, Mocha, PhantomJS, Selenium and NUnit (to name a few).

                                    <br/><br/>

                                    Despite not studying computer science, I believe that I also have strong foundational 
                                    technical knowledge which allows me to adapt well to learning new skills and languages 
                                    to meet specific business goals and solve complex technical problems. I study computer-science 
                                    in my spare time and take computer-science electives which leads to exposure to 
                                    implementing data structures, algorithms and performance considerations when writing code.
                                

                                    <br/><br/>

                                    During my previous work experience, I have had to work closely within my teams and 
                                    also with other teams to achieve business goals. This includes experience in using git/TFS for
                                    source control and working with quality assurance/product teams to design solutions to problems.
                                    Additionally, I had to participate in code-reviews and writing software designs as apart of my previous roles.

                                    <br/><br/>

                                        I also enjoy public speaking at conferences and hackathons.
                                        I've spoken at PyCon Australia (twice!) and I came third in a hackathon 
                                        in my first year of uni. 

                                        <br/><br/>

                                    <b>Final Regards</b>
                                    
                                    <br/>

                                    If you got this far - thanks! 
                                    <br/><br/>

                                    As a note, I've listed front-end as my most interested position mainly 
                                    due to my design background and love for React.
                                    
                                    However, I <i>am</i> interested in web development in general (front-end or back-end) and 
                                    data-analytics/machine-learning/artificial-intelligence 
                                    style work. I'm happy to move around to suit business needs.

                                    <br/><br/>
                                    I am eager to see if we are a match. My best 
                                    form of contact is by email: <b>dion.misic@gmail.com</b>
                                    
                                    <br/>
                                    Thanks for the consideration!

                                    <br/><br/>

                                    <i>Dion Misic</i>
                                </span>
                            )
                    }
                }

                return (
                        <section>
                                <DocumentMeta {...meta}/>
                                <SlantedBg degree="-11"
                                        bgcolor={this.colorPalette.firstcolor}
                                        secondcolor={this.colorPalette.secondcolor}
                                        thirdcolor={this.colorPalette.thirdcolor}/>
                                <Navbar navColour={this.colorPalette.navcolour} colorPalette={this.colorPalette}/>
                                <TransitionWrapper>
                                        <Grid id="content" className="eighty" container spacing={24}>
                                                <Grid item xs={12} sm={12}>
                                                        <div className="down cover-letter">                                                        
                                                                <div className="title-container">
                                                                        <h1 className="pager-title" style={this.fonts}>
                                                                            {company}
                                                                        </h1>
                                                                        <h2 className="sub-title" style={this.fonts}>
                                                                            {role_name}  ― Cover Letter
                                                                        </h2>
                                                                        <hr/>
                                                                </div>  
                                                                <Grid container spacing={40}> 
                                                                        <Grid item xs={12} sm={8}>
                                                                                <p style={this.fonts}>
                                                                                    { generateLetter(company) }
                                                                                </p>
                                                                        </Grid>
                                                                </Grid>
                                                        </div>
                                                </Grid>
                                        </Grid>
                                </TransitionWrapper>
                        </section>
                );
        }
}

export class CoverLetter extends Component {
        constructor(props) {
                super(props);
                this.colorPalette = props.colorPalette;
                this.fonts = { color: this.colorPalette.fontcolour, borderColor: this.colorPalette.fontcolour }
        }

        render() { return (
                <TransitionWrapper>
                        <Footer onlyBottom colorPalette={this.colorPalette}/>
                </TransitionWrapper>);
        }
}