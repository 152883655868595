import Grid from '@material-ui/core/Grid';
import Block from "../util/block";
import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import { Link } from "react-router-dom";
import TrendingFlat from '@material-ui/icons/TrendingFlat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faGithub, faStackOverflow, faTwitter } from '@fortawesome/free-brands-svg-icons';

export class Footer extends Component {
        constructor(props) {
                super(props);
                this.colorPalette = props.colorPalette;
                this.footerfirst = this.colorPalette.footerfirst;
                this.footersecond = this.colorPalette.footersecond;
                this.fonts = { color: this.colorPalette.fontcolour, borderColor: this.colorPalette.fontcolour }
        }
        render() {return (
        <span style={this.fonts}>
                {!this.props.onlyBottom ? 
                <Block nomargin height="100" bgcolor={this.footerfirst}>
                                <Grid className="eighty text-left" style={this.fonts} container spacing={40}>
                                        <Grid item xs={12} md={6}>
                                                <h1 className="jumbo-title">Ready to start your own project?</h1>
                                                <h1 className="second-title">Let's have a chat.</h1>
                                        </Grid>
                                        <br/><br/>
                                        <Grid item xs={12} md={6}>
                                                <Hidden smDown>
                                                        <Link className="down" style={{textDecoration:"none", float:"right"}} to="/contact">
                                                                <Button
                                                                        className={this.colorPalette.fontcolour === "#ffdead" ? "cream-button" : "other"}
                                                                        style={{color:this.colorPalette.fontcolour}}
                                                                        size="large"
                                                                        variant="outlined"
                                                                        color="default">
                                                                        START THE CONVERSATION &nbsp;<TrendingFlat/>
                                                                </Button>
                                                        </Link>
                                                </Hidden>
                                                <Hidden mdUp>
                                                        <Link style={{textDecoration:"none"}} to="/contact">
                                                                <Button
                                                                        className={this.colorPalette.fontcolour === "#ffdead" ? "cream-button" : "other"}
                                                                        style={{color:this.colorPalette.fontcolour}}
                                                                        size="large"
                                                                        variant="outlined"
                                                                        color="default">
                                                                        START THE CONVERSATION &nbsp;<TrendingFlat/>
                                                                </Button>
                                                        </Link>
                                                </Hidden>
                                        </Grid>
                                </Grid>
                </Block>
                : 
                null 
                }

                <Block nomargin bgcolor={this.footersecond}>
                        <Grid className="footer eighty" style={this.fonts} container spacing={8}>
                                <Grid className="text-left-footer footer-txt" item sm={12} md={6}>
                                        <div className="move-me-down">  
                                                 <p id="dow" style={this.fonts}><b>© Dion Misic — Official Website {new Date().getFullYear()}.</b> 
                                                 <br/><br/> 
                                                 <Link style={this.fonts} to="/privacy">Privacy Policy</Link> 
                                                 &nbsp;&nbsp;&nbsp;&nbsp;
                                                 <Link style={this.fonts} to="/about">About the Author</Link> 
                                                 &nbsp;&nbsp;&nbsp;&nbsp;
                                                 <Link style={this.fonts} to="/contact">Contact</Link> 
                                                 &nbsp;&nbsp;&nbsp;&nbsp;
                                                 <Link style={this.fonts} to="/blog">Blog</Link> 
                                                 </p>
                                        </div>
                                </Grid>
                                <Grid className="text-right-footer footer-txt" item sm={12} md={6}>
                                        <section id="social-icons-footer" className="move-me-down">
                                                <a style={this.fonts} href="https://www.linkedin.com/in/dion-misic/">
                                                        <FontAwesomeIcon icon={faLinkedinIn}/>
                                                </a>
                                                <a style={this.fonts} href="https://www.github.com/kingdion">
                                                        <FontAwesomeIcon icon={faGithub}/>
                                                </a>
                                                <a style={this.fonts} href="https://stackoverflow.com/users/9797607/kingdion?tab=profile">
                                                        <FontAwesomeIcon icon={faStackOverflow}/>
                                                </a>
                                                <a style={this.fonts} href="https://twitter.com/dion_misic">
                                                        <FontAwesomeIcon icon={faTwitter}/>
                                                </a>
                                        </section>
                                </Grid>
                        </Grid>
                </Block>

        </span>);

        }
}

export default Footer;
