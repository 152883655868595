import React, { Component } from "react";
import { Link } from "react-router-dom";
import SlantedBg from "../util/slantedbg";
import Navbar from "../util/navbar";
import Footer from "../util/footer";
import Grid from '@material-ui/core/Grid';
import Block from "../util/block";
import Button from '@material-ui/core/Button';
import TrendingFlat from '@material-ui/icons/TrendingFlat';
import Location from '@material-ui/icons/LocationOn';
import TransitionWrapper from "../util/transition";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import DocumentMeta from 'react-document-meta';

export class HomeTop extends Component {
        constructor(props) {
                super(props);
                this.colorPalette = props.colorPalette;
                this.fonts = { color: this.colorPalette.fontcolour, borderColor: this.colorPalette.fontcolour }
        }

        componentDidMount() {
                document.documentElement.style.backgroundColor = this.colorPalette.thirdcolor;
                document.body.style.background = this.colorPalette.thirdcolor;
                document.body.style.borderTop = "7px solid" + this.colorPalette.thirdcolor;
                window.scrollTo(0, 0);
        }

        render() { 
                const meta = {
                        title: "Dion Misic ― Official Website",
                        description: 'The official website of Dion Misic, a fullstack website developer with a goal to create impactful, well-designed and feature driven websites to help businesses make a statement',
                        canonical: 'http://dionmisic.com',
                        index: 'http://dionmisic.com',
                        me: 'http://dionmisic.com/about',
                        author: '/about',
                        license: '/privacy',
                        meta: {
                            charset: 'utf-8',
                            google: 'notranslate',
                            name: {
                                keywords: 'dion, misic, websockets, python, pycon, website, developer, canberra, sydney, designer'
                            }
                        }
                }
        
                return (
                        <section>
                                <DocumentMeta {...meta} />
                                <SlantedBg degree="-11"
                                        bgcolor={this.colorPalette.firstcolor}
                                        secondcolor={this.colorPalette.secondcolor}
                                        thirdcolor={this.colorPalette.thirdcolor}/>
                                <Navbar navColour={this.colorPalette.navcolour} colorPalette={this.colorPalette}/>
                                <TransitionWrapper>
                                        <Grid id="content" className="eighty" container spacing={24}>
                                                <Grid item xs={12} sm={8}>
                                                        <div className="down">
                                                                <h3 className="pager-title" style={this.fonts}>
                                                                        Fullstack web developer & designer.
                                                                </h3>
                                                                <p className="paragraph" style={this.fonts}>
                                                                        I create impactful, well-designed and feature driven websites to help businesses
                                                                        make a <b>statement</b>. I work as an Software Engineer at Nine Entertainment
                                                                        and I am dedicated to building intuitive, well-designed software.

                                                                        <br/><br/>

                                                                        <Link style={{textDecoration:"none"}} to="/contact">
                                                                                <Button className='cream-button' size="large" variant="contained" color="primary">
                                                                                        GET IN TOUCH &nbsp;<TrendingFlat/>
                                                                                </Button>
                                                                        </Link>

                                                                        <Link style={{textDecoration:"none"}} to="https://www.linkedin.com/in/dion-misic/" target="_blank">
                                                                                <Button className='cream-button-reverse' size="large" variant="contained" color="primary">
                                                                                        SEE MY LINKEDIN &nbsp;<TrendingFlat/>
                                                                                </Button>
                                                                        </Link>
                                                                </p>
                                                                <br/>
                                                        </div>
                                                </Grid>
                                        </Grid>
                                        </TransitionWrapper>
                        </section>

                );
        }
}

export class Home extends Component {
        constructor(props) {
                super(props);
                this.colorPalette = props.colorPalette;
                this.fonts = { color: this.colorPalette.fontcolour, borderColor: this.colorPalette.fontcolour }
        }

        render() { 
                var d = new Date();
                var year = d.getFullYear();
                var age = year.toString().slice(2);
                
                return (
                        <TransitionWrapper>
                                <Block balls height="100" bgcolor="transparent">
                                                <Grid className="eighty" container spacing={40}>
                                                        <Grid item xs={12} md={8}>    
                                                                <Location id="award-winning"/>
                                                                <br/>  
                                                                <h1 className="first-title">
                                                                Based in Sydney, Australia.
                                                                </h1>
                                                                <br/><br/>
                                                                <p className="paragraph">
                                                                        I'm a <b>{age} year old web developer</b> and graphic designer based
                                                                        in <i>Sydney, Australia</i> - I design and code websites from the ground up and
                                                                        have <b>won awards</b> such as the ANU Blueprint "Engagement and Impact" award.

                                                                        <br/><br/>
                                                                        Willing to re-locate to London or San Francisco/Bay Area.
                                                                </p>
                                                                <br/>
                                                                <Link style={{textDecoration:"none"}} to="/about">
                                                                        <Button className='cream-button-reverse' size="large" variant="contained" color="primary">
                                                                                READ MORE ABOUT ME &nbsp;<TrendingFlat/>
                                                                        </Button>
                                                                </Link>
                                                </Grid>
                                                <Grid item md={1}></Grid>
                                                <Grid item xs={1} md={5}>
                                                        </Grid>
                                                                </Grid>
                                        <br/>
                                </Block>

                                <div className="main-content">
                                        <SlantedBg onlyLast wrapcolor second degree="-11" bgcolor={this.colorPalette.firstcolor}
                                                secondcolor={this.colorPalette.secondcolor}
                                                thirdcolor="#ffdead"/>
                                        <Grid className="eighty" style={this.fonts} container spacing={24}>
                                                <Grid style={this.fonts} item xs={12}>
                                                        <h3 className="pager-title text-center" style={this.fonts}>
                                                                Publications.
                                                        </h3>
                                                        <p style={this.fonts} className="paragraph text-center">
                                                                A collection of my conference presentations and publications.
                                                        </p>
                                                        <br/>

                                                        <Grid container spacing={16} className="publications">
                                                                <Publication 
                                                                name="PyCon Australia 2017"
                                                                desc="I presented at the PyCon conference in Melbourne, Australia in August of
                                                                        2017 about my research into the Operational
                                                                        Transformation algorithm through the use of Python." 
                                                                embed="https://www.youtube.com/embed/RO59n7rag2U"
                                                                blurb="https://2017.pycon-au.org/schedule/presentation/75/"
                                                                links="https://www.youtube.com/watch?v=RO59n7rag2U"/>

                                                                <Publication 
                                                                name="PyCon Australia 2018"
                                                                desc="My second year speaking at PyCon AU (in Sydney!) about WebSockets as a 
                                                                protocol and how it can be used with Python." 
                                                                embed="https://www.youtube.com/embed/PjiXkJ6P9pQ"
                                                                blurb="https://2018.pycon-au.org/talks/45211-a-beginners-guide-to-websockets/"
                                                                links="https://www.youtube.com/embed/PjiXkJ6P9pQ"/>
                                                        </Grid>
                                                </Grid>
                                        </Grid>
                                </div>

                                <Block nomargin height="100" bgcolor="#202020">
                                        <Grid className="eighty padding-down" style={this.fonts} container spacing={24}>
                                                <Grid className="margin-auto" item sm={8}>
                                                        <h1 className="first-title">Working with the newest technology.</h1>
                                                        <br/><br/>
                                                        <p style={this.fonts} className="paragraph">
                                                                My philosophy is that all websites should be modern, intuitive and responsive for
                                                                all screen-sizes and types - from mobile to desktop. I take pride in my
                                                                knowledge in a variety of different technologies to build whatever - whenever.
                                                        </p>
                                                </Grid>
                                                <Grid item sm={4}></Grid>
                                        </Grid>
                                        <Grid className="eighty techstack" container spacing={0}>
                                                <Grid item className="techstack-inner" xs={12}>
                                                        <div className={"techstack-icon devicon-react-original"}></div>
                                                        <div className={"techstack-icon devicon-python-plain"}></div>
                                                        <div className={"techstack-icon devicon-nodejs-plain"}></div>
                                                        <div className={"techstack-icon devicon-nginx-original"}></div>
                                                        <div className={"techstack-icon devicon-webpack-plain"}></div>
                                                        <div className={"techstack-icon devicon-photoshop-plain"}></div>
                                                        <div className={"techstack-icon devicon-github-plain"}></div>
                                                        <div className={"techstack-icon devicon-postgresql-plain"}></div>
                                                        <div className={"techstack-icon devicon-html5-plain"}></div>
                                                        <div className={"techstack-icon devicon-babel-plain"}></div>
                                                </Grid>
                                        </Grid>
                                </Block>

                                <Footer colorPalette={this.colorPalette}/>
                        </TransitionWrapper>
                );
        }
}

const Publication = ({name, desc, embed, blurb, links}) => {
        return (
                <Grid item sm={6} className="publication">
                        <Card>
                                <iframe title={name} width="100%" height="315" src={embed} frameBorder="0" allow="autoplay; encrypted-media"></iframe>
                                <CardContent>
                                        <Typography gutterBottom variant="headline" component="h2">
                                        {name}
                                        </Typography>
                                        <Typography component="p">
                                        {desc}
                                        </Typography>
                                </CardContent>
                                <CardActions>
                                                <Button href={links} size="small" color="primary">
                                                View
                                                </Button>
                                                <Button href={blurb} size="small" color="primary">
                                                Read Blurb
                                                </Button>
                                </CardActions>
                        </Card>
                </Grid>
        );
}
