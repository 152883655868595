import React, { Component } from "react";
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

class TransitionWrapper extends Component {
        constructor(props) {
          super(props);

          this.children = props.children;
        }

  render() {
    return (
            <ReactCSSTransitionGroup
                        transitionAppear={true}
                        transitionAppearTimeout={600}
                        transitionEnterTimeout={600}
                        transitionLeaveTimeout={200}
                        transitionName="SlideIn"
                      >
                      {this.children}
            </ReactCSSTransitionGroup>
    );
  }
}

export default TransitionWrapper;
