import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';
import TransitionWrapper from '../../util/transition';
import AdComponent from '../../util/adsense';
import Video from '../../util/video';
import { Link } from "react-router-dom";
import DocumentMeta from 'react-document-meta';

export class BlogEntry1 extends Component {
        constructor(props) {
                super(props);
        
                this.colorPalette = props.colorPalette;
                this.fonts = { 
                        color: this.colorPalette.fontcolour, 
                        borderColor: this.colorPalette.fontcolour,
                }
        }

        render() { 
                const meta = {
                        title: "Dion Misic (Blog) ― Welcome",
                        description: 'Welcome to the Blog of Dion Misic, an introduction to the topics this blog may cover.',
                        canonical: 'http://dionmisic.com',
                        index: 'http://dionmisic.com',
                        me: 'http://dionmisic.com/about',
                        author: '/about',
                        license: '/privacy',
                        created: '2019-01-08',
                        meta: {
                            charset: 'utf-8',
                            google: 'notranslate',
                            name: {
                                keywords: 'dion, misic, privacy, policy, websockets, python, pycon, website, developer, canberra, sydney, designer'
                            }
                        }
                }

                return (
                        <section>
                                <DocumentMeta {...meta}/>
                                <TransitionWrapper>
                                        <Grid id="content" className="eighty blogger" container spacing={24}>
                                                <Grid item xs={12} sm={9}>
                                                        <div className="down">                                                        
                                                                <div className="title-container">
                                                                        <h1 className="blog-pager-title" style={this.fonts}>
                                                                                Welcome.
                                                                        </h1>
                                                                        <h2 className="blog-sub-title" style={this.fonts} property="dc:creator" content="Dion Misic">
                                                                                By Dion Misic
                                                                        </h2>
                                                                        <p className="blog-date" style={this.fonts} property="dc:created" content="2019-01-08">
                                                                                08/01/19
                                                                        </p>
                                                                </div>  

                                                                <hr style={this.fonts}/>
                                                                <AdComponent/>
                                                                
                                                                <div style={this.fonts} id="blog-entry-box">  
                                                                        <p>
                                                                                Hi!
                                                                                <br/><br/>
                                                                                Chances are you are reading this because
                                                                                you are interested in what I write about 
                                                                                on my blog, or perhaps when I may add entries to this.
                                                                                Currently, I don't plan to stick to any schedules
                                                                                or particular topics, rather, I will try and talk
                                                                                about whatever comes to my mind (generally in the 
                                                                                technology space). 
                                                                                <br/><br/>
                                                                                If you find anything on this blog helpful, or,
                                                                                if you find something particularly inspiring or 
                                                                                worthy of discussion, feel free to contact me on 
                                                                                my social medias and/or my <Link to="/contact">contact 
                                                                                page.</Link>
                                                                                <br/><br/>
                                                                                Thanks for taking a look!
                                                                                <br/><br/>
                                                                                DM
                                                                        </p>
                                                                </div>
                                                        </div>
                                                </Grid>
                                        </Grid>
                                </TransitionWrapper>
                        </section>
                );
        }
}


// --------------------------------------------------------------------------------------------------- //


export class BlogEntry2 extends Component {
        constructor(props) {
                super(props);
        
                this.colorPalette = props.colorPalette;
                this.fonts = { 
                        color: this.colorPalette.fontcolour, 
                        borderColor: this.colorPalette.fontcolour,
                }
        }

        render() { 
                const meta = {
                        title: "Dion Misic (Blog) ― An Introduction to Colour Grading",
                        description: 'A colour grading/video editing tutorial to teach users about colour grading in DaVinci Resolve.',
                        canonical: 'http://dionmisic.com',
                        index: 'http://dionmisic.com',
                        me: 'http://dionmisic.com/about',
                        author: '/about',
                        license: '/privacy',
                        created: '2019-01-13',
                        meta: {
                            charset: 'utf-8',
                            google: 'notranslate',
                            name: {
                                keywords: 'dion, misic, introduction, to, colour, grading, color'
                            }
                        }
                }

                return (
                        <section>
                                <DocumentMeta {...meta}/>
                                <TransitionWrapper>
                                        <Grid id="content" className="eighty blogger" container spacing={24}>
                                                <Grid item xs={12} sm={9}>
                                                        <div className="down">                                                        
                                                                <div className="title-container">
                                                                        <h1 className="blog-pager-title" style={this.fonts}>
                                                                                An Introduction to Colour Grading
                                                                        </h1>
                                                                        <h2 className="blog-sub-title" style={this.fonts} property="dc:creator" content="Dion Misic">
                                                                                By Dion Misic
                                                                        </h2>
                                                                        <p className="blog-date" style={this.fonts} property="dc:created" content="2019-01-08">
                                                                                08/01/19
                                                                        </p>
                                                                </div>  

                                                                <hr style={this.fonts}/>
                                                                <AdComponent/>
                                                                
                                                                <div style={this.fonts} id="blog-entry-box">  
                                                                        <p>
                                                                                This is a video which I created in my high school
                                                                                media class in 2017 to introduce new video editors
                                                                                to colour grading for film. The video introduces basic
                                                                                colour theory and how to achieve certain "film" 
                                                                                looks using colour grading graphs
                                                                                like the vector, parade and histogram scopes. 
                                                                                <br/><br/>
                                                                                Quite an interesting and fun project, considering
                                                                                I was only 17 when I made it, plus, the project had a 
                                                                                limited time-frame. I focused on making the tutorial as 
                                                                                engaging as possible yet at the same time I tried to 
                                                                                provide as much background knowledge into the topic as I could
                                                                                to cement the idea in the audience's mind. This was my first 
                                                                                proper attempt at making the genre of video (tutorial) and my first 
                                                                                time working with after effects to display valuable information about
                                                                                the topic as I spoke. The project took a lot of effort behind the 
                                                                                scenes, as I had to first write the script and prepare the knowledge
                                                                                I was about to share and I had to think about how that could look 
                                                                                for it to be beneficial to the user. I then had to create all of the 
                                                                                supporting typography effects and colour graded shots, slice it all 
                                                                                together and speak over the top - overall taking a lot of time and 
                                                                                effort; yet nonetheless a fun school project for design, technological and
                                                                                aesthetic reasons.
                                                                                <br/><br/>
                                                                                Feel free to watch the video if you have a particular interest in 
                                                                                videography or behind-the-scenes of film.
                                                                                <br/><br/>
                                                                        </p>
                                                                        <Video>
                                                                                <iframe 
                                                                                title="An Introduction to Colour Grading" 
                                                                                width="560px" height="340px" 
                                                                                src="https://www.youtube.com/embed/M4FQNJ26ro4" 
                                                                                frameBorder="0" 
                                                                                allow="autoplay; encrypted-media"/>                              
                                                                        </Video>
                                                                </div>
                                                        </div>
                                                </Grid>
                                        </Grid>
                                </TransitionWrapper>
                        </section>
                );
        }
}