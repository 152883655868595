import React, { Component } from "react";
import SlantedBg from "../util/slantedbg";
import Navbar from "../util/navbar";
import Footer from "../util/footer";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import TransitionWrapper from "../util/transition";
import TrendingFlat from '@material-ui/icons/TrendingFlat';
import DocumentMeta from 'react-document-meta';
import axios from 'axios';

export class ContactTop extends Component {
        constructor(props) {
                super(props);
                this.colorPalette = props.colorPalette;

                this.fonts = { 
                        color: this.colorPalette.fontcolour, 
                        borderColor: this.colorPalette.fontcolour,
                }

                this.state = {
                        name: '',
                        email: '',
                        message: '',
                        sent: false,
                }

                this.sendForm = this.sendForm.bind(this);
        }

        sent = false;

        componentDidMount() {
                document.documentElement.style.backgroundColor = this.colorPalette.thirdcolor;
                document.body.style.background = this.colorPalette.thirdcolor;
                document.body.style.borderTop = "7px solid" + this.colorPalette.thirdcolor;
                window.scrollTo(0, 0);
        }

        sendForm(e) {
                e.preventDefault();

                axios({
                        method: 'post',
                        headers: {
                                'Content-Type': 'application/json',
                        },
                        url: 'https://us-central1-dion-misic.cloudfunctions.net/sendMail',
                        data: {
                                name: this.state.name, 
                                email: this.state.email, 
                                message: this.state.message 
                        },
                        }).catch(error => {
                        console.log(error);
                        })
                        .then(() => window.location.replace("/"));
        }

        render() { 
                const meta = {
                        title: "Dion Misic ― Contact Me",
                        description: 'Want to talk to Dion? Hire him? Work with him? Use this contact page to directly send him a message.',
                        canonical: 'http://dionmisic.com',
                        index: 'http://dionmisic.com',
                        me: 'http://dionmisic.com/about',
                        author: '/about',
                        license: '/privacy',
                        meta: {
                            charset: 'utf-8',
                            google: 'notranslate',
                            name: {
                                keywords: 'dion, misic, contact, me, websockets, python, pycon, website, developer, canberra, sydney, designer'
                            }
                        }
                }

                return (
                        <section>
                                <DocumentMeta {...meta}/>
                                <SlantedBg degree="-11"
                                        bgcolor={this.colorPalette.firstcolor}
                                        secondcolor={this.colorPalette.secondcolor}
                                        thirdcolor={this.colorPalette.thirdcolor}/>
                                <Navbar navColour={this.colorPalette.navcolour} colorPalette={this.colorPalette}/>
                                <TransitionWrapper>
                                        <Grid id="content" className="eighty" container spacing={24}>
                                                <Grid item xs={12} sm={9}>
                                                        <div className="down">                                                        
                                                                <div className="title-container">
                                                                        <h1 className="pager-title" style={this.fonts}>
                                                                                Contact
                                                                        </h1>
                                                                        <h2 className="sub-title" style={this.fonts}>
                                                                                Send me a message! I'd love to hear from you!
                                                                        </h2>
                                                                </div>  

                                                                <Grid container spacing={40} id="contact-box">  
                                                                        <Grid item xs={12}>

                                                                                <form noValidate autoComplete="off">
                                                                                        <TextField
                                                                                        id="name"
                                                                                        label="Name"
                                                                                        name="name"
                                                                                        margin="normal"
                                                                                        onInput={(e) => this.setState({name: e.target.value})}
                                                                                        />
                                                                                        <br/>

                                                                                        <TextField
                                                                                        id="email"
                                                                                        label="Email"
                                                                                        name="email"
                                                                                        margin="normal"
                                                                                        onInput={(e) => this.setState({email: e.target.value})}
                                                                                        />
                                                                                        <br/>

                                                                                        <TextField
                                                                                        id="message"
                                                                                        label="Message"
                                                                                        margin="normal"
                                                                                        name="message"
                                                                                        multiline={true}
                                                                                        rows={6}
                                                                                        rowsMax={6}
                                                                                        onInput={(e) => this.setState({message: e.target.value})}
                                                                                        />

                                                                                        <br/><br/>
                                                                                        <Button
                                                                                                className={this.colorPalette.fontcolour === "#ffdead" ? "cream-button" : "other"}
                                                                                                style={{color:this.colorPalette.fontcolour}}
                                                                                                size="large"
                                                                                                variant="outlined"
                                                                                                color="default"
                                                                                                onClick={this.sendForm}
                                                                                                type="submit">
                                                                                                SEND MESSAGE&nbsp;<TrendingFlat/>
                                                                                        </Button>
                                                                                </form>
                                                                        </Grid>
                                                                </Grid>
                                                        </div>
                                                </Grid>
                                        </Grid>
                                </TransitionWrapper>
                        </section>
                );
        }
}

export class Contact extends Component {
        constructor(props) {
                super(props);
                this.colorPalette = props.colorPalette;
                this.fonts = { color: this.colorPalette.fontcolour, borderColor: this.colorPalette.fontcolour }
        }

        render() { return (
                <TransitionWrapper>
                        <Footer onlyBottom colorPalette={this.colorPalette}/>
                </TransitionWrapper>);
        }
}