import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Cancel from '@material-ui/icons/Cancel';
import { NavLink } from "react-router-dom";
import LinkedInIcon from '../img/social/linkedin.svg';
import GitHubIcon from '../img/social/github.svg';
import StackOverflowIcon from '../img/social/stackoverflow.svg';
import TwitterIcon from '../img/social/twitter.svg';

const styles = {
        list: {
                width: 'auto',
        },
        fullList: {
                width: 'auto',
        },
};

class SwipeableTemporaryDrawer extends React.Component {
        constructor(props) {
                super(props);
                this.color = props.color;
                this.colorPalette = props.colorPalette
                this.navColour = props.navColour;
        }

        state = {
                right: false,
        };

        toggleDrawer = (side, open) => () => {
                this.setState({
                        [side]: open,
                });
        };

        render() {
                // const { classes } = this.props;
                this.navStyles = {backgroundColor: this.navColour.hamburger};

                const sideList = (
                        <div className={this.darkMode ? "dark" : "light"}>
                                <div className="nav-drawer">
                                        <Grid className={this.darkMode ? "dark" : "light"} container spacing={24}>
                                                <Grid className="menu" item xs={12}>
                                                        <Grid alignItems="center" className="top-of-menu" container spacing={24}>
                                                                <Grid item xs={2}>
                                                                        <h4 className={this.darkMode ? "menu-title-dark" : "menu-title-light"}>MENU</h4>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                        <hr/>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                        <Chip className="bold exit"
                                                                                style=
                                                                                {{
                                                                                        backgroundColor: this.darkMode ? "#131313" : "#ffdead",
                                                                                        color: this.darkMode ? 'white' : '#131313',
                                                                                }}
                                                                                onClick={this.toggleDrawer('right', true)} label="CLOSE"
                                                                                avatar={
                                                                                <Avatar style={{backgroundColor: this.darkMode ? "#131313" : "#ffdead"}}>
                                                                                         <Cancel style={{color: this.darkMode ? 'white' : '#131313'}}/>
                                                                                </Avatar>
                                                                                }
                                                                        />
                                                                </Grid>
                                                        </Grid>

                                                        <nav className="menu-list light">
                                                                <NavLink alt="Home" to="/" className="menu-item">Home </NavLink>
                                                                <NavLink alt="About" to="/about" className="menu-item">About </NavLink>
                                                                <NavLink alt="Contact" to="/contact" className="menu-item">Contact </NavLink>
                                                                <NavLink alt="Privacy" to="/privacy" className="menu-item">Privacy </NavLink>
                                                        </nav> 

                                                        <section id="social-icons">
                                                                <Grid alignItems="center" className="top-of-menu" container spacing={24}>
                                                                        <Grid item xs={2}>
                                                                                <h4 className={this.darkMode ? "menu-title-dark" : "menu-title-light"}>SOCIAL</h4>
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                                <hr/>
                                                                        </Grid>
                                                                </Grid>
                                                                <a href="https://www.linkedin.com/in/dion-misic/">
                                                                        <img src={LinkedInIcon} alt="LinkedIn Link" className="social-icon"/>
                                                                </a>
                                                                <a href="https://www.github.com/kingdion">
                                                                        <img src={GitHubIcon} alt="GitHub Link" className="social-icon"/>
                                                                </a>
                                                                <a href="https://stackoverflow.com/users/9797607/kingdion?tab=profile">
                                                                        <img src={StackOverflowIcon} alt="StackOverflow Link" className="social-icon"/>
                                                                </a>
                                                                <a href="https://twitter.com/dion_misic">
                                                                        <img src={TwitterIcon} alt="Twitter Link" className="social-icon"/>
                                                                </a>
                                                                </section>
                                                        </Grid>
                                                </Grid>
                                        </div>
                                </div>
                        );


                        return (
                                <div>
                                        <div className="hamburger" onClick={this.toggleDrawer('right', true)}>
                                                <div style={this.navStyles} className="ham bar1"></div>
                                                <div style={this.navStyles} className="ham bar2"></div>
                                                <div style={this.navStyles} className="ham bar3"></div>
                                        </div>

                                        <SwipeableDrawer
                                                anchor="right"
                                                width="250px"
                                                open={this.state.right}
                                                onClose={this.toggleDrawer('right', false)}
                                                onOpen={this.toggleDrawer('right', true)}
                                                disableBackdropTransition={!this.props.iOS}
                                                disableDiscovery={this.props.iOS}
                                                >
                                                <div
                                                        anchor="right"
                                                        tabIndex={0}
                                                        role="button"
                                                        onClick={this.toggleDrawer('right', false)}
                                                        onKeyDown={this.toggleDrawer('right', false)}>
                                                        {sideList}
                                                </div>
                                        </SwipeableDrawer>
                                </div>
                        );
                }
        }

        SwipeableTemporaryDrawer.propTypes = {
                classes: PropTypes.object.isRequired,
        };

        export default withStyles(styles)(SwipeableTemporaryDrawer);
