import React, { Component } from "react";
import { Link } from "react-router-dom";
import SlantedBg from "../util/slantedbg";
import Navbar from "../util/navbar";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TransitionWrapper from "../util/transition";
import TrendingFlat from '@material-ui/icons/TrendingFlat';

export class Error extends Component {
        constructor(props) {
                super(props);
                this.colorPalette = props.colorPalette;
                this.fonts = { color: this.colorPalette.fontcolour, borderColor: this.colorPalette.fontcolour }
        }

        render() { return (
                <section>
                        <SlantedBg degree="-11"
                                bgcolor={this.colorPalette.firstcolor}
                                secondcolor={this.colorPalette.secondcolor}
                                thirdcolor={this.colorPalette.thirdcolor}/>
                        <Navbar navColour={this.colorPalette.navcolour} colorPalette={this.colorPalette}/>
                        <TransitionWrapper>
                                <Grid id="content" className="eighty" container spacing={24}>
                                        <Grid item xs={12} sm={9}>
                                                <div className="down">
                                                        
                                                        <h3 className="pager-title" style={this.fonts}>
                                                                404.
                                                        </h3>
                                                        <p className="paragraph" style={this.fonts}>
                                                                Not found. (or coming soon..)
                                                        </p>
                                                        <br/>
                                                        <Link style={{textDecoration:"none"}} to="/">
                                                                <Button className="cream-button" size="large" variant="contained" color="primary">
                                                                        GO HOME &nbsp;<TrendingFlat/>
                                                                </Button>
                                                        </Link>
                                </div>
                        </Grid>
                </Grid>
        </TransitionWrapper>
</section>

);
}
}