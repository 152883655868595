import React, { Component } from "react";
import SlantedBg from "../../util/slantedbg";
import Navbar from "../../util/navbar";
import { Link } from "react-router-dom";
import TrendingFlat from '@material-ui/icons/TrendingFlat';

export class BlogWrapper extends Component {
        constructor(props) {
                super(props);
        
                this.colorPalette = props.colorPalette;
                this.fonts = { 
                        color: this.colorPalette.fontcolour, 
                        borderColor: this.colorPalette.fontcolour,
                }

                this.children = props.children;
        }

        componentDidMount() {
                document.documentElement.style.backgroundColor = this.colorPalette.thirdcolor;
                document.body.style.background = this.colorPalette.thirdcolor;
                document.body.style.borderTop = "7px solid" + this.colorPalette.thirdcolor;
                window.scrollTo(0, 0);
        }

        render() { 
                return (
                        <section>
                                <SlantedBg degree="-11"
                                        bgcolor={this.colorPalette.firstcolor}
                                        secondcolor={this.colorPalette.secondcolor}
                                        thirdcolor={this.colorPalette.thirdcolor}/>
                                <Navbar navColour={this.colorPalette.navcolour} colorPalette={this.colorPalette}/>
                                <div className="eighty">
                                    <Link style={this.fonts} id="back-to-blogs" to="/blog">
                                        <TrendingFlat className="flat-reverse"/> BACK TO BLOG MENU
                                    </Link>
                                </div>  
                                {this.children}
                        </section>
                );
        }
}