import React, { Component } from "react";

export default class Video extends Component {
    constructor(props) {
        super(props);
        this.children = props.children;
    }

    render() {
        return (
            <div className="videoWrapper">
                {this.children}
            </div>
        );
    }
}