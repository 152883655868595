import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class Navtop extends Component {
        constructor(props) {
                super(props);
                this.colorPalette = props.colorPalette
                this.darkMode = props.darkMode;
                this.navStyles = {color: props.navColour.text, textDecoration: 'none'};
        }

        render() {
            return (
                    <nav className="menu-list-horizontal">
                        <NavLink style={this.navStyles} activeStyle={this.navStyles} className="menu-item" alt="Home" to="/">Home </NavLink>
                        <NavLink style={this.navStyles} activeStyle={this.navStyles} className="menu-item" alt="About" to="/about">About </NavLink>
                        <NavLink style={this.navStyles} activeStyle={this.navStyles} className="menu-item" alt="Contact" to="/contact">Contact </NavLink>
                        <NavLink style={this.navStyles} activeStyle={this.navStyles} className="menu-item" alt="Privacy" to="/privacy">Privacy </NavLink>
                    </nav>
            );
        }
}
export default Navtop;
