import React, { Component } from "react";
import logo from '../img/DionMisic.png';
import Grid from '@material-ui/core/Grid';
import Navdraw from "../util/navdraw";
import Navtop from "../util/navtop";
import { Link } from "react-router-dom";

class Navbar extends Component {
        constructor(props) {
                super(props);
                this.colorPalette = props.colorPalette;
                this.iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
                this.darkMode = props.darkMode;
                this.navColour = props.navColour;
                this.state = {
                        width: null,
                        height: null,
                }
                this.updateDimensions = this.updateDimensions.bind(this);
        }
        updateDimensions() {
                var w = window,
                    d = document,
                    documentElement = d.documentElement,
                    body = d.getElementsByTagName('body')[0],
                    width = w.innerWidth || documentElement.clientWidth || body.clientWidth,
                    height = w.innerHeight|| documentElement.clientHeight|| body.clientHeight;
            
                    this.setState({width: width, height: height});
        }

        componentWillMount() {
                this.updateDimensions();
        }

        componentDidMount() {
                window.addEventListener("resize", this.updateDimensions);
        }

        componentWillUnmount() {
                window.removeEventListener("resize", this.updateDimensions);
        }

        render() {
                let fullWidthNav = false;
                if (this.state.width > 1000) {
                        fullWidthNav = true;
                } else {
                        fullWidthNav = false;
                }
                return (
                        <header>
                                <Grid id="navbar" className="eighty" container spacing={40}>
                                        <Grid item xs={6} sm={3}>
                                                <Link to="/">
                                                        <img alt="Dion Misic 2018. Logo" className="logo" src={logo}></img>
                                                </Link>
                                        </Grid>

                                        <Grid item xs={6} sm={9}>
                                               {fullWidthNav
                                               ? <Navtop navColour={this.navColour} colorPalette={this.colorPalette} />
                                               : <Navdraw navColour={this.navColour} ios={this.iOS} colorPalette={this.colorPalette} />
                                               }
                                        </Grid>

                                </Grid>
                        </header>
                );
        }
}

export default Navbar;
